import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  TransferFormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  FormWrapper,
  FormLabel,
  FormText,
  OptionLabel,
} from './VaspTransferForm.elements'

// Hooks
import { useSearchVasp, useFetchVasp, useFetchRecipientVasp } from 'core/hooks/api'

// Utils
import { getCustomerStatus } from 'common/utils/customerValidation'

// Views
import { Button, TextField, Search, Select } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function VaspTransferForm(props) {
  // Destructure
  const {
    formData,
    setFormData,
    actions,
    recipientAddress,
    setRecipientAddress,
    recipientVaspOptions,
    setRecipientVaspOptions,
  } = props

  // Store Actions
  const { setShowHeaderLoader, setIsVaspDrawerOpen, setToBeUpdatedVasp } = actions

  // hooks
  const { searchVasp, vaspSearchData, isVaspSearchLoading } = useSearchVasp()
  const { getVasp, vaspData, isVaspLoading } = useFetchVasp()
  const { getRecipientVasp, recipientVaspData, isRecipientVaspLoading, recipientVaspError } = useFetchRecipientVasp()

  // Internal State
  const [searchResults, setSearchResults] = useState([])
  const [isVaspNotInList, setIsVaspNotInList] = useState(false)
  const [csi] = useState(getCustomerStatus())

  // functions
  const handleVaspSearch = debounce((value) => {
    if (!value) return setSearchResults([])
    if (value) return searchVasp({ search_term: value, is_customer_only: csi !== 1 })
  }, 500)
  const handleOnAutoCompleteSelect = (value) => {
    getVasp({ vasp_id: value })
  }
  const handleVaspUpdate = () => {
    setFormData({ ...formData, vasp: { ...formData.vasp, ...vaspData } })
  }
  const handleVaspSelect = (value) => {
    if (value === null) setIsVaspNotInList(true)
    if (value !== null) setIsVaspNotInList(false)
    setFormData({ ...formData, vasp: value })
  }

  // useEffects
  useEffect(() => {
    if (vaspSearchData) {
      setSearchResults(() =>
        vaspSearchData.map(({ name_business, name_legal, id }) => ({
          label: name_business,
          subLabel: name_legal,
          value: id,
        }))
      )
    }
    if (!vaspSearchData) setSearchResults([])
  }, [vaspSearchData])
  useEffect(() => vaspData && handleVaspUpdate(), [vaspData])
  useEffect(() => {
    if (isVaspLoading) {
      setShowHeaderLoader(true)
      setFormData({ ...formData, vasp: null })
    }
    if (!isVaspLoading) setShowHeaderLoader(false)
  }, [isVaspLoading])
  useEffect(() => {
    if (recipientAddress !== formData.transfer.out_hash_address) {
      setFormData({ ...formData, vasp: null })
      setRecipientAddress(formData.transfer.out_hash_address)
      // getRecipientVasp({
      //   out_hash_address: formData.transfer.out_hash_address,
      //   asset_code: formData.transfer.asset_code,
      // })
    }
  }, [formData.transfer.out_hash_address])
  useEffect(() => {
    if (recipientVaspData) {
      const formattedOptions = []
      recipientVaspData?.map((item) => {
        formattedOptions.push({
          value: item,
          label: item.name_business,
        })
      })
      formattedOptions.push({
        value: null,
        label: 'My VASP is not on this list',
      })
      setRecipientVaspOptions(formattedOptions)
    }
    if (recipientVaspError) setRecipientVaspOptions(null)
  }, [recipientVaspData, recipientVaspError])

  return (
    <TransferFormWrapper>
      <FormWrapper>
        <FormGroupWrapper>
          {isRecipientVaspLoading && <FormLabel>Loading...</FormLabel>}
          {recipientVaspOptions && !isRecipientVaspLoading && (
            <FormInputGroupItem>
              <Select placeholder="Select VASP" options={recipientVaspOptions} onChange={handleVaspSelect} />
            </FormInputGroupItem>
          )}
          {!isRecipientVaspLoading && isVaspNotInList && (
            <FormGroupWrapper>
              <FormText>Suggestions:</FormText>
              <FormText>∙ Use the search bar below to find the recipient VASP </FormText>
              <Search
                placeholder="Search for VASP"
                onInputChange={handleVaspSearch}
                options={searchResults}
                setOptions={setSearchResults}
                onSelect={handleOnAutoCompleteSelect}
                isLoading={isVaspSearchLoading}
                // bottomOptions={
                //   <FormGroupWrapper>
                //     <OptionLabel>Create New Vasp</OptionLabel>
                //     <FormInputGroupItem>
                //       <Button
                //         variant="outlined"
                //         onClick={() => {
                //           setToBeUpdatedVasp(null)
                //           setIsVaspDrawerOpen(true)
                //         }}
                //       >
                //         VASP
                //       </Button>
                //     </FormInputGroupItem>
                //   </FormGroupWrapper>
                // }
              />
            </FormGroupWrapper>
          )}
          {/* {!isRecipientVaspLoading && !recipientVaspOptions && (
            <FormGroupWrapper>
              <FormText>Blockchain Analytic search could not find a VASP based on recipient&apos;s address.</FormText>
              <FormText>Suggestions:</FormText>
              <FormText>∙ Check your recipient wallet address</FormText>
              <FormText>∙ Try a different recipient wallet address</FormText>
              <FormText>∙ Use the search bar below to find the recipient VASP </FormText>
              <Search
                placeholder="Search for VASP"
                onInputChange={handleVaspSearch}
                options={searchResults}
                setOptions={setSearchResults}
                onSelect={handleOnAutoCompleteSelect}
                isLoading={isVaspSearchLoading}
                bottomOptions={
                  <FormGroupWrapper>
                    <OptionLabel>Create New Vasp</OptionLabel>
                    <FormInputGroupItem>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setToBeUpdatedVasp(null)
                          setIsVaspDrawerOpen(true)
                        }}
                      >
                        VASP
                      </Button>
                    </FormInputGroupItem>
                  </FormGroupWrapper>
                }
              />
            </FormGroupWrapper>
          )} */}
          <FormGroupWrapper>
            <Search
              placeholder="Search for VASP"
              onInputChange={handleVaspSearch}
              options={searchResults}
              setOptions={setSearchResults}
              onSelect={handleOnAutoCompleteSelect}
              isLoading={isVaspSearchLoading}
              // bottomOptions={
              //   <FormGroupWrapper>
              //     <OptionLabel>Create New Vasp</OptionLabel>
              //     <FormInputGroupItem>
              //       <Button
              //         variant="outlined"
              //         onClick={() => {
              //           setToBeUpdatedVasp(null)
              //           setIsVaspDrawerOpen(true)
              //         }}
              //       >
              //         VASP
              //       </Button>
              //     </FormInputGroupItem>
              //   </FormGroupWrapper>
              // }
            />
          </FormGroupWrapper>

          {formData.vasp && !isVaspLoading && !isRecipientVaspLoading && (
            <>
              <FormLabel>Selected VASP</FormLabel>
              <FormInputGroupItem>
                <TextField label="VASP Name" disabled value={formData.vasp.name_business} />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField label="Email" disabled value={formData.vasp.email} />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField label="Website" disabled value={formData.vasp.website} />
              </FormInputGroupItem>
            </>
          )}
          {isVaspLoading && <FormLabel>Loading...</FormLabel>}
        </FormGroupWrapper>
      </FormWrapper>
    </TransferFormWrapper>
  )
}

// Default Props
VaspTransferForm.defaultProps = {
  formData: {},
  setFormData: () => {},
  actions: {},
  form: {},
}

// Proptypes Validation
VaspTransferForm.propTypes = {
  formData: PropTypes.shape({
    transfer: PropTypes.shape({
      out_hash_address: PropTypes.string,
      asset_code: PropTypes.string,
    }),
    vasp: PropTypes.shape({
      name_business: PropTypes.string,
      email: PropTypes.string,
      website: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setIsVaspDrawerOpen: PropTypes.func,
    setToBeUpdatedVasp: PropTypes.func,
  }),
  form: PropTypes.shape({
    createdVasp: PropTypes.shape({}),
  }),
  recipientAddress: PropTypes.string,
  setRecipientAddress: PropTypes.func,
  setFormData: PropTypes.func,
  recipientVaspOptions: PropTypes.arrayOf([]),
  setRecipientVaspOptions: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(VaspTransferForm)
